.case-details {
  padding: 200px 0 100px 0;
  .svg-img {
    position: absolute;
    top: 30%;
    left: -7%;
    animation: move_top 5s infinite linear;
    @include desktop {
      display: none;
    }
  }
  .animate-shape {
    position: absolute;
    top: 29%;
    right: 4%;
    height: 300px;
    width: 300px;
    svg {
      width: 100%;
    }
    @include desktop {
      display: none;
    }
  }
  .animate-pattern {
    position: absolute;
    top: 46%;
    right: 12%;
    height: 100px;
    width: 100px;
    animation: move_top 3s infinite linear;
    img {
      height: 100%;
      width: 100%;
    }
    @include desktop {
      display: none;
    }
  }
  &-service {
    margin-top: 2em;
  }
  &-info {
    display: flex;
    justify-content: space-around;
    background: $secondary;
    border-radius: 20px;
    padding: 45px 0;
    margin: 70px 0 80px 0;
    @include mobile {
      flex-direction: column;
      padding: 30px 0;
    }
    .case-details-info-item {
      position: relative;
      &:not(:last-child) {
        &::before {
          position: absolute;
          content: "";
          width: 1px;
          height: 100%;
          top: 0;
          right: -80px;
          background: rgba($color: $white, $alpha: 0.1);
          @include desktop {
            right: -45px;
          }
          @include tablet {
            right: -15px;
          }
          @include mobile {
            display: none;
          }
        }
        @include mobile {
          margin-bottom: 30px;
        }
      }
    }
    h5 {
      color: $light;
    }
    p {
      color: $light;
      margin: 0;
      padding-left: 20px;
    }
    li {
      color: $light;
      list-style: none;
    }
    a {
      color: $primary-light;
      // font-weight: bold;
    }
  }
  &-thumb {
    overflow: hidden;
    margin-top: 15vh !important;
    @include tablet {
      margin-top: 8vh !important;
    }
    margin-bottom: 4em;
    img {
      height: 100%;
      max-width: 100%;
    }
  }
  &-content {
    text-align: left;
    h3 {
      margin-top: 2em;
    }
  }
  &-shortdescription {
    font-size: larger;
  }
  &-nav {
    .previous,
    .next {
      .icon {
        line-height: 0;
      }
      .content {
        span {
          display: block;
        }
        h5 {
          font-size: 20px;
          margin: 0;
        }
      }
    }

    .social {
      @include mobile {
        display: none;
      }
      ul {
        li {
          list-style: none;
          display: inline-block;
          a {
            color: $body-color;
            display: block;
            transition: all 0.3s ease;
            &:hover {
              color: $primary;
            }
          }
          &:not(:last-child) {
            margin-right: 25px;
          }
        }
      }
    }
  }
}
