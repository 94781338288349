.about {
  overflow: hidden;
  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &-thumb {
      width: 45%;
      position: relative;
      @include desktop {
        width: 100%;
      }
      .about-svg {
        position: absolute;
        top: -11%;
        left: -17%;
        z-index: -1;
        height: 150px;
        animation: move_top 4s infinite;
        @include tablet {
          top: -7%;
          left: -16%;
        }
        img {
          height: 100%;
          width: 100%;
        }
      }
      .about_content-thumb-image {
        img {
          height: 100%;
          width: 100%;
          &.about_mask_svg {
            mask: url(../images/about/about-mask-svg.svg);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            -webkit-mask-position: center center;
          }
          @include desktop {
            display: none;
          }
        }
      }
    }
    &-inner {
      width: 55%;
      padding: 60px 50px;
      background: #1b2031;
      border-radius: 20px;
      margin-left: -60px;
      position: relative;
      @include desktop {
        padding: 35px;
        padding-top: 3em;
      }
      @include desktop {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
      }
      &-blob {
        position: absolute;
        top: -55%;
        right: -22%;
        z-index: -1;
        width: 400.369px;
        height: 362.909px;
        svg {
          width: 140%;
        }
        @include tablet {
          display: none;
        }
      }
    }
  }
  &-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin-bottom: 0.5em;
    }
  }
}
