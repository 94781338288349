
.frame {
  background: #ececec;
  display: inline-block;
  padding: 0px;
  max-width: 100%;
  margin-top: 1.2em;
  margin-bottom: 3em;
  position: relative;
  border-radius: 8px 8px 0 0;
  text-align: right;
  box-shadow:
    0px 1.3px 5.3px rgba(0, 0, 0, 0.028),
    0px 4.5px 17.9px rgba(0, 0, 0, 0.042),
    0px 20px 80px rgba(0, 0, 0, 0.07);
  img {
    display: block;
  }
  img + em {
    text-align: center;
    margin-right: 20px;
    font-size: 0.8em;
  }

  &-browser {
  background: #ececec;
  display: inline-block;
  padding: 0px;
  max-width: 100%;
  margin-top: 1.2em;
  margin-bottom: 3em;
  position: relative;
  border-radius: 8px 8px 0 0;
  text-align: right;
  box-shadow:
  0px 1.3px 5.3px rgba(0, 0, 0, 0.028),
  0px 4.5px 17.9px rgba(0, 0, 0, 0.042),
  0px 20px 80px rgba(0, 0, 0, 0.07);

  &::before {
    background: #FFBD2D;
    text-align: right;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 12px;
    left: -2rem;
    top: 0px;
    position: relative;
    width: 12px;
    box-shadow: -20px 0 0 #ff5f57, 20px 0 0 #29c940;
  }
  img {
    display: block;
  }
  img + em {
    text-align: center;
    margin-right: 20px;
    font-size: 0.9em;
  }
}
}