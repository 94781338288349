.resume{
    position: relative;
    margin-bottom: 100px;
    padding: 150px 0;
    @include desktop{
        margin-bottom: 40px;
        padding: 100px 0;
    }
    &__background{
        background: #1B2031;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: skewY(-2deg);
    }
    &__heading{
        margin-right: 100px;
        position: sticky;
        position: -webkit-sticky;
        top: 150px;
        @include desktop{
            margin-right: 0;
            margin-bottom: 30px;
        }
        span{
            &::before{
                background: $light;
            }
        }
    }
    &__education{
        &_item{
            background: $white;
            padding: 30px;
            border-radius: 20px;
            margin-bottom: 30px;
            box-shadow: 0px 20px 40px 0px rgba(50, 65, 141, 0.12);
            span{
                display: block;
                margin: 0 0 20px 30px;
                position: relative;
            }
        }
    }
}