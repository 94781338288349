//
// Colors
//

// General
$primary: #5d78ff;
$primary-light: #73aefa;
$primary-shiny: #5de9d2;
$primary-flashy: #ff7088;
$secondary: #282f49;
// Text
$headings-color: #282f49;
$body-color: #454547;

//
// Typography
//

// Fonts
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900|Yeseva+One&display=swap");
$font-family-base: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;
$headings-font-family: "Yeseva One", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;

// Weight
$font-weight-bolder: 600;

// Size
$font-size-base: 1.25rem; // 18px
$font-size-md: $font-size-base * 1.10 !default;
$h1-font-size: 55px;
$h1-font-size-sm: 50px;
$h2-font-size: 50px;
$h3-font-size: 30px;
$h4-font-size: 25px;
$h5-font-size: 22px;
$h6-font-size: 16px;

// Spacing
$headings-margin-bottom: 1.5rem;
$line-height-base: 1.75;

// Misc
$link-hover-decoration: none;
