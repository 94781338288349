.blog-preview {
	position: relative;
	margin-bottom: 30px;
	&__shape {
		position: absolute;
		top: 11%;
		right: 12%;
		height: 300px;
		width: 300px;
		img {
			animation: rotate 25s infinite linear;
			height: 100%;
			width: 100%;
		}
		@include desktop {
			display: none;
		}
	}
	&__header_button {
		position: relative;
		width: 100%;
		height: 100%;
		a {
			position: absolute;
			bottom: 0;
			right: 0;
			display: inline-block;
			@include desktop {
				position: relative;
				margin-top: 30px;
			}
		}
	}
	&__item {
		position: relative;
		margin-bottom: 20px;
		@include desktop {
			margin-bottom: 130px;
		}
		&-thumb {
			width: 90%;
			height: 100%;
			overflow: hidden;
			border-radius: 20px;
			@include desktop {
				width: 80%;
				margin: 0 auto;
			}
			img {
				height: 100%;
				width: 100%;
				transition: all 0.3s ease;
			}
		}
		&-content {
			position: absolute;
			bottom: -100px;
			left: 40px;
			padding: 30px;
			border-radius: 20px;
			box-shadow: 0px 20px 40px 0px rgba(50, 65, 141, 0.12);
			@include desktop {
				left: 0;
			}
			span {
				color: $primary;
				margin: 0 0 20px 40px;
				position: relative;
				display: block;
				line-height: 1;
				&::before {
					position: absolute;
					content: "";
					top: 50%;
					left: -40px;
					height: 1px;
					width: 20px;
					background: $secondary;
					transform: translateY(-50%);
				}
			}
		}
		&:hover {
			.blog-preview__item-thumb img {
				transform: scale(1.1);
			}
		}
	}
	.mobile {
		display: none;
	}
	@include desktop {
		.desktop {
			display: none;
		}
		.mobile {
			display: block;
			text-align: center;
		}
	}
}
@keyframes rotate {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}
