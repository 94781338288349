.base-font {
    font-family: $font-family-base;
}

* {
    transition: all 0.3s ease;
}

a.text-light,
a.text-dark {
    // transition: all 0.3s ease;
    &:hover {
        color: $primary !important;
    }
}

.top-title {
    font-size: 20px;
    display: block;
    color: $dark;
    @extend .mb-3;
}

.pre-line {
    margin-left: 20px;
    position: relative;
    &::before {
        position: absolute;
        // content: "";
        height: 2px;
        width: 20px;
        top: 50%;
        left: -20px;
        transform: translateX(-50%);
        background: $secondary;
    }
}

// This fixes the fact that with `markdownify` we can't add `text-light`
// directly onto the generated `h1`, `h2` etc.
.text-white {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
}
@each $color, $value in $theme-colors {
    .text-#{$color} {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        span {
            color: $value;
        }
    }
}
