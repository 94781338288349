.tech-stack {
  p {
    margin-top: 2em;
    font-size: 1.1em;
  }

  .text-center {
    margin-top: 3em;
    p {
      margin: 0.5em;
      margin-bottom: 0.5em;
    }
  }
}