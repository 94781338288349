html {
  scroll-behavior: smooth;
}


.section{
  padding: 300px 0;
  scroll-padding-top: 3em;
  scroll-margin-top: 3em;
}

.unstyle-list {
  list-style-type: none;
  padding-left: 0;
  margin-right: 0;
}

li.nav-item > a {
  font-size: 0.95em !important;
  &::after {
    content: "";
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    width: 0px;
    background-color: $primary;
  }
  &:hover::after {
    width: 100%;
    transition: all 0.3s;
  }
}
