.service {
    position: relative;
    overflow: hidden;
    padding-bottom: 275px;
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 61%;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    &__background_shape {
        position: absolute;
        bottom: -10%;
        left: -7%;
        height: 465px;
        width: 410px;
        svg {
            width: 100%;
        }
        @include desktop {
            display: none;
        }
    }
    &__background_pattern {
        position: absolute;
        bottom: 4%;
        left: 6%;
        animation: move_top 5s infinite linear;
        @include desktop {
            display: none;
        }
    }
    &__slider {
        &_item {
            margin: 15px;
            padding: 30px;
            box-shadow: 0px 20px 40px 0px rgba(50, 65, 141, 0.12);
            border-radius: 20px;
            background: $white;
            &-icon {
                height: 110px;
                width: 125px;
                position: relative;
                .icon-background {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                .icon {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }
        .slick-list {
            overflow: visible;
        }
        .slick-dots {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            justify-content: center;
            li {
                margin: 0;
                list-style: none;
                height: 8px;
                width: 8px;
                &:not(:last-child) {
                    margin-right: 15px;
                }
                button {
                    cursor: pointer;
                    height: 100%;
                    width: 100%;
                    background: #c2c8cc;
                    border-radius: 50%;
                    transition: all 0.3s ease;
                    display: block;
                    padding: 0;
                    text-indent: -9999px;
                    &:focus {
                        outline: 0;
                    }
                    &:hover {
                        background: $primary;
                    }
                }
                &.slick-active {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    button {
                        background-color: $primary;
                    }
                }
            }
        }
        .slick-slide {
            &:focus {
                outline: 0;
            }
        }
    }
}
