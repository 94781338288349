.skill {
  position: relative;
  padding: 100px 0 150px 0;
  @include desktop {
    padding: 100px 0;
  }
  &__background_shape {
    position: absolute;
    bottom: 2%;
    right: 2%;
    height: 300px;
    width: 300px;
    svg {
      width: 100%;
    }
    @include desktop {
      display: none;
    }
  }
  &__background_pattern {
    position: absolute;
    bottom: 5%;
    right: 10%;
    height: 100px;
    width: 100px;
    animation: move_top 3s infinite linear;
    img {
      height: 100%;
      width: 100%;
    }
    @include desktop {
      display: none;
    }
  }
  &__thumb {
    position: relative;
    @include tablet {
      margin-bottom: 30px;
    }
    &_pattern {
      position: absolute;
      top: -11%;
      left: -17%;
      z-index: -1;
      height: 150px;
      animation: move_top 4s infinite linear;
      img {
        height: 100%;
        width: 100%;
      }
    }
    &_image {
      margin-right: 7%;
      @include desktop {
        margin-right: 0;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &__progress {
    margin-left: 40px;
    @include desktop {
      margin-left: 0;
      margin-top: 30px;
    }
    &_item {
      &.js-animation {
        transition: all 0.3s ease;
        .progress {
          width: 100%;
          background: $light;
          height: 10px;
          border-radius: 5px;
          .progress-bar {
            border-radius: 5px;
            position: relative;
            animation: animate-positive 3000ms;
            background: $primary;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
