.breadCrumb {
    padding: 200px 0 0;
    position: relative;
    .svg-img {
        position: absolute;
        bottom: -35%;
        left: -7%;
        animation: move_top 5s infinite linear;
        @include desktop {
            display: none;
        }
    }
    .animate-shape {
        position: absolute;
        bottom: -60%;
        right: 2%;
        height: 300px;
        width: 300px;
        svg {
            width: 100%;
        }
        @include desktop {
            display: none;
        }
    }
    .animate-pattern {
        position: absolute;
        bottom: -40%;
        right: 10%;
        height: 100px;
        width: 100px;
        animation: move_top 3s infinite linear;
        img {
            height: 100%;
            width: 100%;
        }
        @include desktop {
            display: none;
        }
    }
    @include desktop {
        padding: 120px 0 0;
    }
    &__title {
        font-size: 48px;
        line-height: 1;
        margin: 0 0 30px 0;
    }
    &__subtitle {
      font-size: 28px;
      line-height: 1;
      margin: 0 0 80px 0;
  }
    .breadcrumb {
        background: none;
        margin: 0;
        padding: 0;
        &-item {
            a {
                color: $secondary;
                text-decoration: none;
            }
            &.active {
                color: $secondary;
            }
        }
    }
    .breadcrumb-item + .breadcrumb-item::before {
        content: "-";
    }
}


// Handle long words overflow in Titles

@include mobile-s {
  .hero h1 {
    font-size: 40px;
  }
  .case-details-title h1 {
    font-size: 30px;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  h2 {
    font-size: 2.2rem;
  }
}

@include mobile {
  .case-details-title h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 2.6rem;
  }
}
