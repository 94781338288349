.contact__info {
  &_item {
    box-shadow: 0px 20px 40px 0px rgba(50, 65, 141, 0.12);
    padding: 50px 40px;
    border-radius: 20px;
    &-icon {
      height: 110px;
      width: 125px;
      position: relative;
      margin-bottom: 35px;
      .icon-background {
        position: absolute;
        top: 0;
        right: 0;
      }
      .icon {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
    }
    a {
      color: $body-color;
      text-decoration: underline;
    }
  }
}
.contact-form {
  padding: 50px 0 100px 0;
  &-input {
    .form-group {
      margin-bottom: 30px;
    }
  }
  #map {
    border-radius: 30px;
    height: 100%;
    @include desktop {
      height: 400px;
      margin-top: 60px;
    }
  }
  .form-check-label {
    font-size: $font-size-base;
    margin-bottom: 1.2em;
  }
  .form-check-input {
    margin-top: 0.5rem;
  }
}
